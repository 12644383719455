var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "SNOWS-full-dialog",
      attrs: {
        visible: _vm.visible,
        fullscreen: "",
        "lock-scroll": "",
        "show-close": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "SNOWS-full-dialog-header" },
        [
          _c("div", { staticClass: "header-title" }, [
            _c("img", {
              staticClass: "header-logo",
              attrs: { src: require("@/assets/images/sinomas.png") },
            }),
            _c("p", { staticClass: "header-txt" }, [_vm._v(" · 在线开发")]),
          ]),
          !_vm.loading
            ? _c(
                "el-steps",
                {
                  class: "steps steps" + (_vm.maxStep + 1),
                  attrs: {
                    active: _vm.activeStep,
                    "finish-status": "success",
                    simple: "",
                  },
                },
                [
                  _c("el-step", {
                    attrs: { title: "基础设置" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.stepChick(0)
                      },
                    },
                  }),
                  _c("el-step", {
                    attrs: { title: "表单设计" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.stepChick(1)
                      },
                    },
                  }),
                  _vm.maxStep >= 2
                    ? _c("el-step", {
                        attrs: { title: "列表设计" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.stepChick(2)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "options" },
            [
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.activeStep <= 0 },
                  on: { click: _vm.prev },
                },
                [_vm._v("上一步")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.activeStep >= _vm.maxStep || _vm.loading,
                  },
                  on: { click: _vm.next },
                },
                [_vm._v("下一步\n      ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.loading,
                    loading: _vm.btnLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.dataFormSubmit()
                    },
                  },
                },
                [_vm._v("保存\n      ")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "main",
        },
        [
          !_vm.activeStep
            ? _c(
                "el-row",
                {
                  staticClass: "basic-box",
                  attrs: { type: "flex", justify: "center", align: "middle" },
                },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "basicForm",
                      attrs: { xs: 12, sm: 12, md: 12, lg: 12, xl: 10 },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "dataForm",
                          attrs: {
                            model: _vm.dataForm,
                            rules: _vm.dataRule,
                            "label-width": "80px",
                            "label-position": "right",
                          },
                          nativeOn: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "功能名称", prop: "fullName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "功能名称",
                                  maxlength: "100",
                                },
                                model: {
                                  value: _vm.dataForm.fullName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "fullName", $$v)
                                  },
                                  expression: "dataForm.fullName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "功能编码", prop: "enCode" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "功能编码",
                                  maxlength: "50",
                                },
                                model: {
                                  value: _vm.dataForm.enCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "enCode", $$v)
                                  },
                                  expression: "dataForm.enCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "启用流程", prop: "enableFlow" },
                            },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                },
                                model: {
                                  value: _vm.dataForm.enableFlow,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "enableFlow", $$v)
                                  },
                                  expression: "dataForm.enableFlow",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "功能排序", prop: "sortCode" } },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 0,
                                  max: 999999,
                                  "controls-position": "right",
                                },
                                model: {
                                  value: _vm.dataForm.sortCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "sortCode", $$v)
                                  },
                                  expression: "dataForm.sortCode",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "功能说明", prop: "description" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "功能说明",
                                  type: "textarea",
                                  rows: 3,
                                },
                                model: {
                                  value: _vm.dataForm.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "description", $$v)
                                  },
                                  expression: "dataForm.description",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "数据连接" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择数据库",
                                    clearable: "",
                                  },
                                  on: { change: _vm.onDbChange },
                                  model: {
                                    value: _vm.dataForm.linkId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.dataForm, "linkId", $$v)
                                    },
                                    expression: "dataForm.linkId",
                                  },
                                },
                                _vm._l(_vm.dbOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.dbName,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-table",
                            {
                              staticClass: "SNOWS-common-table",
                              attrs: {
                                data: _vm.mainTable,
                                size: "medium",
                                "empty-text":
                                  "点击“新增”可选择1条(单表)或2条以上(多表)，未选择数据表时系统将会自动创建数据表",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "index",
                                  label: "序号",
                                  width: "50",
                                  align: "center",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "typeId",
                                  label: "类别",
                                  width: "65",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.typeId == "1"
                                            ? _c("el-tag", [_vm._v("主表")])
                                            : _c(
                                                "el-tag",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  attrs: {
                                                    type: "warning",
                                                    title: "点击设置成主表",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changeTable(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "从表\n                "
                                                  ),
                                                ]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2024797863
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "table", label: "表名" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  scope.row.tableName ||
                                                  scope.row.table,
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(scope.row.table)),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1916873032
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "tableField",
                                  label: "外键字段",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return scope.row.typeId !== "1"
                                          ? [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value: scope.row.tableField,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "tableField",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.tableField",
                                                  },
                                                },
                                                _vm._l(
                                                  scope.row.fields,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.filedName,
                                                      attrs: {
                                                        label: item.filedName,
                                                        value: item.filedName,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          : undefined
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "relationField",
                                  label: "关联主键",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return scope.row.typeId !== "1"
                                          ? [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "请选择",
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.relationField,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "relationField",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.relationField",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.mainTableFields,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.filedName,
                                                      attrs: {
                                                        label: item.filedName,
                                                        value: item.filedName,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          : undefined
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  fixed: "right",
                                  width: "50",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "SNOWS-table-delBtn",
                                              attrs: {
                                                size: "mini",
                                                type: "text",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delItem(
                                                    scope.row,
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("移除\n                ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2291124763
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "table-actions",
                              on: { click: _vm.openTableBox },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", icon: "el-icon-plus" },
                                },
                                [_vm._v("新增一行")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.activeStep == 1
            ? [
                _c("Generator", {
                  ref: "generator",
                  attrs: {
                    conf: _vm.formData,
                    modelType: _vm.dataForm.type,
                    webType: _vm.dataForm.webType,
                    dbType: _vm.dbType,
                  },
                }),
              ]
            : _vm._e(),
          _vm.activeStep == 2
            ? [
                _c("columnDesign", {
                  ref: "columnDesign",
                  attrs: {
                    columnData: _vm.columnData,
                    appColumnData: _vm.appColumnData,
                    modelType: _vm.dataForm.type,
                    webType: _vm.dataForm.webType,
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("TableForm", {
        ref: "tableForm",
        attrs: { visible: _vm.formVisible, linkId: _vm.dataForm.linkId },
        on: {
          "update:visible": function ($event) {
            _vm.formVisible = $event
          },
          closeForm: _vm.closeForm,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }